<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          v-if="canVerb(resource, 'store')"
          :id="`popover-create`"
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="code"
                type="text"
                label="Codice Agenzia"
                v-model="filter.byAttribute.code"
                placeholder="Inserisci una codice agenzia"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="code_importer"
                type="text"
                label="Codice Compagnia"
                v-model="filter.byAttribute.code_importer"
                placeholder="Inserisci un codice compagnia"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="title"
                type="text"
                label="Ragione Sociale Intermediario Coass"
                v-model="filter.byAttribute.title"
                placeholder="Inserisci una ragione sociale"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="title_importer"
                type="text"
                label="Compagnia Rappresentata"
                v-model="filter.byAttribute.title_importer"
                placeholder="Inserisci una compagnia"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <insurer-participants
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      @destroy="onDestroy"
      :ref="tableRef"
      :onlyActions="['destroy', 'edit']"
      :filterOn="{
        byRelations: ['byCorrespondence'],
      }"
    ></insurer-participants>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import InsurerParticipants from "@/components/tables/InsurerParticipants.vue";
import BaseInput from "@/components/form/BaseInput";
import { mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      repository: "insurer_participant",
      resource: "insurer_participants",
      tableRef: "InsuererParticipantsTableRef",
      filterName: "filterInsurerParticipants",
      filter: this.initFilter(),
      user_count: null,
      args: {},
      fields: [
        {
          key: "title",
          label: this.getDictionary("title", "insurer_participant"),
          sortable: true,
        },
        {
          key: "code",
          label: this.getDictionary("code", "insurer_participant"),
          sortable: true,
        },
        {
          key: "title_importer",
          label: this.getDictionary("title_importer", "insurer_participant"),
          sortable: true,
        },
        {
          key: "code_importer",
          label: this.getDictionary("code_importer", "insurer_participant"),
          sortable: true,
        },
      ],
    };
  },
  components: {
    InsurerParticipants,
    BaseInput,
  },
  created() {
    /* */
    // debugger;
    console.log("created index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("councils"))
      // this.getResources("auth/councils");
      // resources.push({
      //   path: path,
      //   value: "councils",
      // });
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {
            console.info(`background data "${path}councils" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
        // .finally(() => {
        //   this.unloadMsg();
        //   this.isLoading = false;
        // })
      );
    if (!this.hasLoadedResource("provinces"))
      // this.getResources("auth/provinces");
      // resources.push({
      //   path: path,
      //   value: "provinces",
      // });
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {
            console.info(
              `background data "${path}provinces" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
        // .finally(() => {
        //   this.unloadMsg();
        //   this.isLoading = false;
        // })
      );
    if (!this.hasLoadedResource("countries")) {
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {
            console.info(
              `background data "${path}countries" fetched correctly`
            );
            // const defaultCountryId = this.getCountries().find(
            //   (country) => country.text == this.defaultCountryName
            // ).value;
            // this.opt_provinces = this.getProvinces()(defaultCountryId);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
        // .finally(() => {
        //   this.unloadMsg();
        //   this.isLoading = false;
        // })
      );
    }
    // else {
    //   const defaultCountryId = this.countries().find(
    //     (country) => country.text == this.defaultCountryName
    //   ).value;
    //   this.opt_provinces = this.provinces()(defaultCountryId);
    // }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          // const defaultCountryId = this.countries().find(
          //   (country) => country.text == this.defaultCountryName
          // ).value;
          // this.opt_provinces = this.provinces()(defaultCountryId);
          // this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
          // this.$emit("storageLoading", false);
        });
    } else {
      this.isLoading = false;
      // this.$emit("storageLoading", false);
    }
    /* */
    // const defaultCountryId = this.countries().find(
    //   (country) => country.text == this.defaultCountryName
    // ).value;
    // this.opt_provinces = this.provinces()(defaultCountryId);
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          title: null,
          code: null,
          code_importer: null,
          title_importer: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Compagnia Coass eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>

<template>
  <div class="mt-1 mx-3">
    <edit-insurer-participants> </edit-insurer-participants>
  </div>
</template>

<script>
import EditInsurerParticipants from "@/components/profile/insurer-participants/edit.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    EditInsurerParticipants,
  },
  created() {
    this.notifyQueue({
      text: "Modifica",
      title: "Modifica Compagnia Coass",
      path: "insurer_participants.edit",
      level: 3,
    });
  },
};
</script>

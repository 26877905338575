<template>
  <div class="mt-1 mx-3">
    <insurer-participants> </insurer-participants>
  </div>
</template>

<script>
import InsurerParticipants from "@/components/profile/insurer-participants/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    InsurerParticipants,
  },
  created() {
    this.notifyQueue({
      text: "Profilo",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Compagnie Coass",
      path: "insurer_participants.index",
      level: 2,
    });
  },
};
</script>

<template>
  <div class="mt-1 mx-3">
    <create-insurer-participants> </create-insurer-participants>
  </div>
</template>

<script>
import CreateInsurerParticipants from "@/components/profile/insurer-participants/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CreateInsurerParticipants,
  },
  created() {
    this.notifyQueue({
      text: "Crea",
      title: "Crea Compagnia Coass",
      path: "insurer_participants.create",
      level: 3,
    });
  },
};
</script>
